import algoliasearch from "algoliasearch/lite";

export const indexName = process.env.REACT_APP_ALGOLIA_INDEX_NAME + "_cars_index";
export const accessoriesIndexName = process.env.REACT_APP_ALGOLIA_INDEX_NAME + "_accessories_index";
// export const accessoriesIndexName = "stage_cars_index_price_asc";
// export const indexName = "local_lo_cars_index";
export const searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_API_KEY
);

/**
 * Client used for autocomplete to disable empty requests
 * Can be used to only disable analytics if we want initial renders
 * For example, initializing the client with a query
 * https://www.algolia.com/doc/guides/getting-insights-and-analytics/search-analytics/out-of-the-box-analytics/how-to/how-to-remove-empty-search-from-analytics/
 * The proxy is used to completely remove the request
 * https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-requests/react/?client=js#implementing-a-proxy
 * A valid search query must contain at least 4 characters different from space " "
 * 26.06.24 update: increased from 2 characters to minimum 4
 */
export const nonEmptySearchClient = {
    search(requests) {
        if (requests.every(({ params }) => params.query.replace(/\s/g, '').length <= 3)) {
            return;
        }
        return searchClient.search(requests);
    },
};

export const sortItems = [
    {value: indexName, label: "default"},
    {value: indexName + '_price_asc', label: "€ - €€€"},
    {value: indexName + '_price_desc', label: "€€€ - €"},
    {value: indexName + '_discount_asc', label: "Crescator"},
    {value: indexName + '_discount_desc', label: "Descrescator"},
];

export const sortAccessories = [
    {value: accessoriesIndexName, label: "default"},
    {value: accessoriesIndexName + '_price_asc', label: "€ - €€€"},
    {value: accessoriesIndexName + '_price_desc', label: "€€€ - €"},
    {value: accessoriesIndexName + '_discount_asc', label: "Crescator"},
    {value: accessoriesIndexName + '_discount_desc', label: "Descrescator"},
];

export const resetLabel = 'Reseteaza';
export const staticOrder = ["arrival_date_segment", "model", "echipare", "fuel", "car_body", "transmission", "transmission_type", "colors", "interior_color", "interior_material", "client_invoice_price", "price_range", "dealerships"];
export const dictionary = {
    "model": "Model",
    "fuel": "Combustibil",
    "car_body": "Caroserie",
    "transmission": "Transmisie",
    "transmission_type": "N/A",
    "colors": "Culoare",
    "interior_color": "Culoare interior",
    "interior_material": "material interior",
    "client_invoice_price": "Pret",
    "price_range": "Pret",
    "dealerships": "Dealer",
    "standard_euro_emissions": "Emisie",
    "engine": "Motor",
    "equipment": "Echipament suplimentar",
    /// Accessories
    "Categories": "Categorie", // Extracted from Categories.lvl due to hierarchical menu
    "years": "An",
    "final_price": "Pret",
    "models": "Model"
};

export const accCategoryDictionary = {
    "Bicycle Holder": "Suport bicicleta",
    "Charging": "Incarcare",
    "Charging cable": "Cablu de incarcare",
    "Child Safety Equipment": "Echipamente de siguranta pentru copii",
    "Child Safety Seat": "Scaun de siguranta pentru copii",
    "Climate": "Climatizare",
    "Comfort & Convenience": "Confort & Convenienta",
    "Complete All Season Wheels": "Roti complete pentru toate anotimpurile",
    "Complete Summer Wheels": "Roti complete de vara",
    "Complete Winter Wheels": "Roti complete de iarna",
    "Driver's Support": "Asistenta pentru sofer",
    "Exterior": "Exterior",
    "Exterior Styling": "Stilizare exterioara",
    "Interior": "Interior",
    "Interior Styling": "Stilizare interioara",
    "Load Carriers": "Suporturi de incarcare",
    "Load Compartment": "Compartiment de incarcare",
    "Load Securing": "Securizare incarcatura",
    "Optimisation": "Optimizare",
    "Pack & Load": "Ambalare & Incarcare",
    "Performance": "Performanta",
    "Preventive Safety": "Siguranta preventiva",
    "RSE": "Sistem de divertisment pentru pasageri",
    "Roof Boxes": "Cutii pentru acoperis",
    "Safety & Security": "Siguranta & Securitate",
    "Seats": "Scaune",
    "Security": "Securitate",
    "Sport Equipment Holder": "Suport echipament sportiv",
    "Storage": "Depozitare",
    "Tech & Sounds": "Tehnologie & Sunet",
    "Towing": "Remorcare",
    "Wallbox": "Statie de incarcare",
    "Wheels": "Roti"
};

/**
 * Hide facet via CSS, so it can be applied, but remain hidden from users
 * @type {string}
 */
export const dealershipFacetName = "dealerships";

/**
 * Hide facet via CSS, so it can be applied, but remain hidden from users
 * And enable filtering via `inStock=true` query
 * @type {string}
 */
export const inStockFacetName = "arrival_date";
export const inStockDelay = 14;

export const expandedPanels = ["arrival_date_segment", "model", "echipare", "fuel", "client_invoice_price", "price_range"];

export const hiddenPanels = [
    "total_car_price",
    "transmission_type",
    "standard_euro_emissions", // Removed from Algolia
    "engine", // Removed from Algolia
    "equipment", // Removed from Algolia
    "discount_percentage",
    "ecobonus",
    "client_invoice_price", // To be removed after switching to price_range static intervals.
];

export const expandedAccPanels = ["category", "models", "final_price", "hierarchical"];

export const hiddenAccPanels = [
    "base_price",
    "discount_percentage",
    "discount_price"
];

export const priceItems = [
    {id: 1, label: '< 30.000€', end: 30000},
    {id: 2, label: '30.000€ - 40.000€', start: 30000, end: 40000},
    {id: 3, label: '40.000€ - 55.000€', start: 40000, end: 55000},
    {id: 4, label: '55.000€ - 75.000€', start: 55000, end: 75000},
    {id: 5, label: '> 75.000€', start: 75000},
];

/*These rely on date which it throws an error in modules*/
// const availabilityItems = [];

export const priceAccItems = [
    {id: 1, label: '< 100€', end: 100},
    {id: 2, label: '100€ - 500€', start: 100, end: 500},
    {id: 3, label: '500€ - 1.000€', start: 500, end: 1000},
    {id: 4, label: '1.000€ - 5.000€', start: 1000, end: 5000},
    {id: 5, label: '> 5.000€', start: 5000},
];

/**
 * Display a color circle for color facets
 */
export const colorMap = {
    "Onyx Black": "#2d2926",
    "Bright Dusk": "#e1dcd8",
    "Vapour Grey": "#a6a7aa",
    "Denim Blue": "#4f5a78",
    "Platinum Grey": "#4c4743",
    "Moss Yellow": "#e9e95d",
    "Crystal White Pearl": "#f2f2f2",
    "Crystal White": "#f2f2f2",
    "Silver Dawn": "#d1d1d2",
    "Cloud Blue": "#c8d4e1",
    "Fusion Red": "#BC3D3A",
    "Cloud Blue, Solid": "#c8d4e1",
    "Sand Dune": "#efe9dd",
    "Sage Green": "#98a097",
    "Thunder Grey": "#4D4E53",
    "Fjord Blue": "#677f9c",
    "Mulberry Red": "#8e5564",
    "Fjord Blue Metallic": "#677f9c"
};
