import React, {useEffect, useState} from 'react'
import clsx from "clsx";
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from "react-router";
import Fade from "react-reveal/Fade";
import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";
import _has from "lodash/has";
import {dealershipFacetName} from "../../constants/algolia";

import {makeStyles} from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';

import * as actions from "../../actions/products"
import {fetchPage} from "../../actions/pages"
import SectionBox from "../../components/SectionBox";
import GetOfferForm from "../../components/forms/GetOfferForm";
import ProgressFullScreen from "../../components/ProgressFullScreen";
import Icon from "@material-ui/core/Icon";
import toLocale from "../../helpers/toLocale";
import Skeleton from '@material-ui/lab/Skeleton';
import {PAGES_SLUGS} from "../../constants";
import ReactHtmlParser from "react-html-parser";
import Seo from "../../components/seo/Seo";
import getSuppotedFileType from "../../helpers/getSuppotedFileType";
import {appStyle} from "../../theme/appStyle";
import itemDetailsStyles from "../../theme/itemDetailsStyles";
import {availabilityOffset, getYearAndWeek} from "../../helpers/inStock";
import GetOfferCardBlueWeeks from "./GetOfferCardBlueWeeks";
import {fetchBestPriceByModel} from "../../actions/products";
import GetOfferSliderParent from "./GetOfferSliderParent";
import {_endDate} from "../../components/BlueWeeks/Countdown";

function GetOfferPage(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {slug} = useParams();

    const product = useSelector(state => _get(state.products.data, slug, {}));
    const loading = useSelector(state => state.products.request);
    const error = useSelector(state => state.products.error);
    const bestPriceData = useSelector(state => state.bestPrice.data);

    const pageData = useSelector(state => _get(state.pages.data, PAGES_SLUGS.OFFERS, {}));
    const pageDataLoading = useSelector(state => state.pages.request);
    const pageDataError = useSelector(state => state.pages.error);

    const promoEnded = (_endDate - new Date().getTime()) < 1000;

    useEffect(() => {
        window.scrollTo(0, 0);
        if (_isEmpty(product)) {
            dispatch(actions.fetchProduct(slug))
        }
    }, [])

    useEffect(() => {
        if (_isEmpty(pageData)) {
            dispatch(fetchPage(PAGES_SLUGS.OFFERS))
        }
    }, [])

    useEffect(() => {
        if (_isEmpty(bestPriceData)) {
            dispatch(fetchBestPriceByModel())
        }
    }, [])

    return (
        <React.Fragment>
            {!_isEmpty(_get(pageData, 'data.meta_data', {})) && <Seo data={pageData.data.meta_data}/>}
            {!_isEmpty(product) && !pageDataLoading && <>
                {!_isEmpty(_get(pageData, 'data.content', {})) && <Fade>
                    <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        flexDirection={"column"}
                        className={classes.banner}
                        style={{
                            "--background-image": `url(${_get(pageData, 'data.content[0].attributes.side_image', "")})`
                        }}
                    >
                        <Fade delay={1000}>
                            <Typography component={'div'}
                                        className={classes.bannerTitle}>{ReactHtmlParser(_get(pageData, "data.content[0].attributes.title", ''))}</Typography>

                            <Typography align={"center"} component={'div'}
                                        className={classes.bannerText}>{ReactHtmlParser(_get(pageData, "data.content[0].attributes.content", ''))}</Typography>
                        </Fade>
                    </Box>
                </Fade>}
                <SectionBox>
                    <Container>
                        <Grid container>
                            <Grid item container direction={"column"} sm={6} xs={12}>
                                <ProductCard data={product}/>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <GetOfferForm
                                    rom_ref={_get(product, "configuration_id", '')}
                                    dealerships={_get(product, dealershipFacetName, [])}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </SectionBox>
                {!promoEnded &&
                <GetOfferSliderParent/>
                }
            </>}
            {loading &&
            <ProgressFullScreen loading={loading}/>
            }
        </React.Fragment>
    )
}


const useStyles = makeStyles((theme) => ({
    ...appStyle(theme),
    banner: {
        backgroundImage: `linear-gradient(to right, rgba(0,0,0,0.35), rgba(0,0,0,0.35)), var(--background-image)`,
        backgroundSize: "cover",
        backgroundPosition: "center bottom",
        minHeight: 540,
        padding: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            minHeight: 350,
        },
    },
    bannerTitle: {
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(38),
        lineHeight: theme.typography.pxToRem(56),
        letterSpacing: "0.4",
        color: "#fff",
    },
    bannerText: {
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(16),
        lineHeight: theme.typography.pxToRem(24),
        color: "#fff",
    },
    /*Which element?*/
    [theme.breakpoints.up('md')]: {
        maxWidth: 350,
    }
}))

const ProductCard = (props) => {
    const {data} = props;
    const classes = useProductCardStyles();
    const [loading, setLoading] = useState(true);

    const promoEnded = (_endDate - new Date().getTime()) < 1000;

    const onLoad = () => {
        setLoading(false);
    }

    const showDiscountPrice = Boolean(data.client_invoice_price) && data.client_invoice_price !== data.total_car_price;

    return <Box display={"flex"} flexDirection={"column"} flex={1} className={classes.root}>
        <Box
            display={"flex"}
            justifyContent={"flex-start"}
            alignItems={"center"}
        >
            <Icon className={clsx("brand-icon-car", classes.sectionIcon)}/>
            <Typography
                variant="h2"
                component="h2"
                className={classes.sectionTitle}
            >
                1. Modelul selectat</Typography>
        </Box>

        {(getYearAndWeek(availabilityOffset.now.end) >= _get(data, "arrival_date_segment", 21000101) && !promoEnded)
            ? <GetOfferCardBlueWeeks data={data}/>
            : <div className={classes.productCardRoot}>
                {!_isEmpty(_get(data, "photos", [])) && <div className={clsx(classes.productImage)}>
                    <img
                        src={getSuppotedFileType(_get(data, "photos[0].thumb", []))}
                        className={classes.image}
                        onLoad={onLoad}
                    />
                    {loading && <Skeleton variant="rect" width={"100%"} height={207}/>}
                </div>
                }
                <Typography
                    className={classes.sku}
                    component="p"
                >
                    # {_get(data, 'rom_ref', '')}
                </Typography>
                <Typography
                    variant="h1"
                    component="h1"
                    className={classes.title}
                >
                    {_get(data, 'name', '')}
                </Typography>
                <div className={classes.optionsRoot}>
                    <Box display={"flex"} flexWrap={"wrap"} alignItems={"center"}>
                        {_has(data, "fuel") && <Box display={"flex"} alignItems={"center"} className={classes.optionItem}>
                            <Icon className={clsx("brand-icon-fuel", classes.icon)}/>
                            <Typography variant="caption">{_get(data, "fuel", '')}</Typography>
                        </Box>}
                        {_has(data, "transmission_type") &&
                            <Box display={"flex"} alignItems={"center"} className={classes.optionItem}>
                                <Icon className={clsx("brand-icon-transmission", classes.icon)}/>
                                <Typography variant="caption">{_get(data, "transmission_type", '')}</Typography>
                            </Box>}
                        {_has(data, "transmission") && <Box display={"flex"} alignItems={"center"} className={classes.optionItem}>
                            <Icon className={clsx("brand-icon-traction", classes.icon)}/>
                            <Typography variant="caption">{_get(data, "transmission", '')}</Typography>
                        </Box>}

                    </Box>
                </div>
                <Typography
                    component={"div"}
                    className={classes.rootPrice}>
                    Pret final: <span
                    className={classes.price}>{toLocale(_get(data, 'client_invoice_price', ''))}€ + TVA</span>
                    {showDiscountPrice &&
                        <div className={classes.oldPrice}>
                            Redus de la:
                            <span className={classes.lineThrough}>
                        {toLocale(_get(data, 'total_car_price', ''))}€
                    </span> + TVA
                        </div>
                    }
                </Typography>
            </div>
        }
    </Box>

}


const useProductCardStyles = makeStyles((theme) => ({
    ...appStyle(theme),
    ...itemDetailsStyles(theme),
    productCardRoot: {
        backgroundColor: "#FAFAFA",
        padding: theme.spacing(3),
        flex: 1,
    },
    productImage: {
        paddingBottom: theme.spacing(3),
    },
    image: {
        maxWidth: "100%",
    },
    sku: {
        color: '#707070',
        fontSize: '14px',
        fontWeight: '300',
        letterSpacing: 0,
        lineHeight: '24px',
        paddingBottom: theme.spacing(2),

    },
    title: {
        coloe: "#141414",
        letterSpacing: "0.4px",
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: theme.typography.pxToRem(26),
        lineHeight: theme.typography.pxToRem(32),
        paddingBottom: theme.spacing(0),
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.pxToRem(20),
            lineHeight: theme.typography.pxToRem(32),
        },
    },

    rootPrice: {
        ...itemDetailsStyles(theme).rootPrice,
        paddingBottom: theme.spacing(3)
    },
    lineThrough: {
        textDecoration: "line-through",
    },
    optionsRoot: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(2),
    },
    icon: {
        fontSize: "20px",
        marginRight: theme.spacing(2),
    },
    optionItem: {
        marginRight: theme.spacing(2),
        paddingBottom: theme.spacing(1),
    },
    sectionTitle: {
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    }
}))

export default GetOfferPage;
