import {inStockDelay} from "../constants/algolia";

const getAvailabilityDateNumber = () => {
    let todayDate = new Date()
    const offset = todayDate.getTimezoneOffset()
    const availabilityDateLimit = new Date(todayDate.getTime()
        - (offset * 60 * 1000)
        + (inStockDelay * 24 * 60 * 60 * 1000));

    return availabilityDateLimit.toISOString().split('T')[0].replace(/-/g,"");
};
/**
 * Add to current day inStockDelay and transform it to yyyymmdd number
 * For a car to be available, this number needs to be greater than arrival_date
 * Basically, all cars that will arrive in less than 2 weeks (greater or equal to)
 */
export const availabilityDateNumber = getAvailabilityDateNumber();


/**
 * @param yearWeek Older cars will have 202400
 * @returns {number} How far, in weeks, is the date in the future
 * Current week is 0, last week is -1 and next week is 1
 */
export function weeksToDate(yearWeek) {
    const year = Math.floor(yearWeek / 100); // Get the first four characters as year
    const week = yearWeek % 100; // Get the last two characters as week

    // Get the starting date from year and week number
    // Mon Nov 04 2024 00:00:00 GMT+0200 (Eastern European Standard Time)
    const startDate = new Date(year, 0, (week - 1) * 7 + 1); // First day of the given week

    // Get today's date
    const currentDate = new Date();

    // Calculate the difference in time
    const diffInTime = startDate - currentDate;

    // Return the difference in weeks
    return Math.floor(diffInTime / (1000 * 60 * 60 * 24 * 7)) + 1;
}

/**
 * @param offset Number of weeks
 * @returns {number} Returns the date as an int in yyyyww format. The week is 1-based
 */
export function getYearAndWeek(offset = 0) {
    const currentDate = new Date();
    // Add the offset to the current date
    currentDate.setDate(currentDate.getDate() + offset * 7);

    const year = currentDate.getFullYear();
    const startOfYear = new Date(year, 0, 1);
    const days = Math.floor((currentDate - startOfYear) / (24 * 60 * 60 * 1000));
    const week = Math.ceil((days + startOfYear.getDay() + 1) / 7);

    const weekString = week.toString().padStart(2, '0');
    return parseInt(`${year}${weekString}`);
}

export const availabilityOffset = {
    now: {end: 4},
    soon: {start: 5, end: 8},
    later: {start: 9, end: 104},
}


export const staticPriceRanges = [
    {id: 1, label: 'Disponibil imediat', end: getYearAndWeek(availabilityOffset.now.end)},
    {id: 2, label: 'Disponibil peste 4 saptamani', end: getYearAndWeek(availabilityOffset.soon.end)},
    {id: 3, label: 'Disponibil peste 8 saptamani', end: getYearAndWeek(availabilityOffset.later.end)},
];
