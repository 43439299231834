import React from 'react';
import _sortBy from "lodash/sortBy";
import _get from "lodash/get";

import {dealershipFacetName, expandedPanels, hiddenPanels, inStockFacetName, priceItems} from "../../constants/algolia";

import CustomRangeInput from "../ContentBlocks/Cars/AlgoliaInputMenu";
import AlgoliaFiltersDesktop from "../ContentBlocks/Cars/AlgoliaFiltersDesktop";
import StockRangeInput from "../ContentBlocks/Cars/AlgoliaStockMenu";
import AvailabilityInput from "../ContentBlocks/Cars/AlgoliaAvailabilityMenu";
import extractFilterName from "../../helpers/extractFilterName";

const RenderCarFilters = (props) => {
    const {facets, isDealerPage} = props;
    const isMobileFilter = _get(props, 'isMobileFilter', false);

    if (facets.length !== 0) {
        return (
            facets.map((item, index) => {
                if (hiddenPanels.includes(item)) return null;
                /**
                 * Using custom numeric input filtering for prices
                 * TODO: Not anymore!
                 * TODO: CLEANUP! It's only added in hiddenPanels
                 */
                if (item === "client_invoice_price") return null;/* <CustomRangeInput
                    key={`${index}${item}`}
                    attribute='client_invoice_price'
                    expanded={expandedPanels.includes("client_invoice_price")}
                    staticPriceRanges={priceItems}
                />*/
                /**
                 * Custom stripped-down (and hidden) inputs for availability
                 */
                if (item === inStockFacetName) return <StockRangeInput
                    key={`${index}${item}`}
                    attribute={inStockFacetName}
                    expanded={true}
                />
                /**
                 * Using custom predefined ranges for availability
                 */
                if (item === "arrival_date_segment") return  <AvailabilityInput
                    key={`${index}${item}`}
                    attribute='arrival_date_segment'
                    expanded={expandedPanels.includes("arrival_date_segment")}
                />
                /**
                 * Dealership expanded on mobile only
                 */
                return (
                    <AlgoliaFiltersDesktop
                        expanded={expandedPanels.includes(extractFilterName(item)) || (dealershipFacetName === extractFilterName(item) && isMobileFilter)}
                        key={`${index}${item}`}
                        attribute={item}
                        transformItems={items =>
                            _sortBy(items, 'label')
                        }
                        isDealerPage={isDealerPage}
                        showMore={true}
                    />
                )
            })
        )
    }

    return null;
};

export default RenderCarFilters
