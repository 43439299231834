import React, {Component} from 'react';
import {connectRange} from 'react-instantsearch-dom';
import {Box, withStyles} from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import filterStyles from "../../../theme/filterStyles";
import {resetLabel} from "../../../constants/algolia";
import {PAGES_SLUGS} from "../../../constants";
import {availabilityOffset, getYearAndWeek, staticPriceRanges} from "../../../helpers/inStock";

const customStyles = theme => ({
    ...filterStyles(theme),
});

class AvailabilityInput extends Component {
    state = {
        min: '',
        max: '',
        selectedId: null,
        hideAvailability: false,
    }

    componentDidMount() {
        const queryParams = new URLSearchParams(window.location.search);
        const isBlue = window.location.href.includes(PAGES_SLUGS.BLUE_WEEK);

        const hasFlag = queryParams.get('inStock') || isBlue;
        hasFlag && this.setState({hideAvailability: true});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.currentRefinement.min !== this.props.currentRefinement.min
            || prevProps.currentRefinement.max !== this.props.currentRefinement.max
        ) {
            this.setFiltersValues();
        }
    }

    setFiltersValues = () => {
        const priceRange = this.props.currentRefinement;
        if (!priceRange.min && !priceRange.max) return null;

        /**
         * TODO: Algolia sends min and max values from the API (default?) which overwrite the currentRefinement object's values
         * We won't do anything to it, but change the `checked` mechanics of the checkboxes and adjust
         * the following mechanics to achieve the desired resuld
         */

        /**
         * Hack-ish... We check the max refine with availabilityOffset.later.end which is too far for it to be true
         * This way, the checkbox won't be checked on page load
         * BUT, it will be checked via predefinedRefinement function while the URL will be cleared of the query because
         * the range is set to the initial min-max
         */
        if (priceRange.max === getYearAndWeek(availabilityOffset.now.end)) {
            this.setState({selectedId: 1});
        } else if (priceRange.max === getYearAndWeek(availabilityOffset.soon.end)) {
            this.setState({selectedId: 2});
        } else if (priceRange.max === getYearAndWeek(availabilityOffset.later.end)) {
            this.setState({selectedId: 3});
        }
    }

    predefinedRefinement = (values, id) => {
        const refineMax = values.max >= this.props.max ? this.props.max : values.max;
        /**
         * Can't use refine function is values exceed the min-max interval
         * But there is no issue when converting the URL to search state
         */

        if (this.state.min === '' && this.state.max === '' && this.state.selectedId === id) {
            this.clearRefinement();
            return null;
        }

        this.setState({
            min: '',
            max: '',
            selectedId: id,
        }, this.props.refine({min: undefined, max: refineMax}))
    }

    clearRefinement = () => {
        this.setState({
            min: '',
            max: '',
            selectedId: null
        }, this.props.refine({min: '', max: ''}))
    }

    render() {
        const {currentRefinement, classes, expanded} = this.props;
        // console.log('currentRefinement in numericMenu', currentRefinement);
        // console.log(this.props);
        // console.log(this.state);
        return (
            <div className={`${classes.root} ${this.state.hideAvailability && classes.hidden}`}>
                <div className={classes.filterBox}>
                    <Accordion defaultExpanded={expanded} classes={{root: classes.expansionRoot}}>
                        <AccordionSummary
                            classes={{
                                root: classes.expansionButton,
                                content: classes.expansionButtonContent,
                                expandIcon: classes.icon
                            }}
                            expandIcon={<span className={`brand-icon-down-arrow`}/>}>
                            <Typography variant={"body1"}
                                        className={`${classes.categoryTitle} ${(this.state.selectedId) && classes.selectedFilters}`}>
                                Livrare
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.details}>
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormGroup>
                                    {staticPriceRanges.map(item => {
                                        if (
                                            item.end < this.props.min
                                            || item.start > this.props.max
                                        ) {
                                            return null;
                                        }

                                        return (
                                            <FormControlLabel
                                                key={item.id}
                                                className={classes.formLabel}
                                                classes={{label: classes.customLabel}}
                                                control={
                                                    <Checkbox
                                                        checkedIcon={
                                                            <div className={classes.customCheckbox}>
                                                            </div>}
                                                        icon={
                                                            <div className={classes.emptyCustomCheckbox}>
                                                            </div>}
                                                        color={'primary'}
                                                        className={classes.smallerPadding}
                                                        checked={item.id === this.state.selectedId}
                                                        onClick={event => {
                                                            event.preventDefault();
                                                            this.predefinedRefinement({
                                                                min: item.start,
                                                                max: item.end
                                                            }, item.id)
                                                        }}
                                                        value={item.label}/>
                                                }
                                                label={item.label}
                                            />
                                        )
                                    })}

                                    <Box alignSelf="flex-start">
                                        <Typography
                                            variant={"body2"}
                                            onClick={this.clearRefinement}
                                            disabled={!(currentRefinement.min || currentRefinement.max)}
                                            className={
                                                `${classes.resetButton} ${classes.capitalize} 
                                            ${!(currentRefinement.min || currentRefinement.max)
                                                    ? classes.disabled
                                                    : classes.pointer}`
                                            }
                                        >
                                            {resetLabel}
                                        </Typography>
                                    </Box>

                                </FormGroup>
                            </FormControl>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
        );
    }
}

AvailabilityInput = withStyles(customStyles)(AvailabilityInput);

AvailabilityInput = connectRange(AvailabilityInput);
export default AvailabilityInput;
