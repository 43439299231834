import React, {useEffect} from 'react'

import {Link as RouterLink, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import {Box} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import SpaceBox from "../../components/SpaceBox";
import SectionBox from "../../components/SectionBox";
import Fade from "react-reveal/Fade";

import BannerSlider from "../../components/sliders/BannerSlider";
import SearchBox from "../../components/SearchBox";
import OfferCard from "../../components/OfferCard";
import BestOfferSlider from "../../components/sliders/BestOfferSlider";
import LastMileOfferSlider from "../../components/sliders/LastMileOfferSlider";
import BlueWeeksSlider from "../../components/sliders/BlueWeeksSlider";
import TestDriveSection from "../../components/forms/TestDriveSection";
import Icon from "@material-ui/core/Icon";

import * as actions from "../../actions/pages";
import {fetchBestOfferByModel, fetchBestPriceByModel} from "../../actions/products";
import {PAGES_SLUGS} from "../../constants";
import Seo from "../../components/seo/Seo";
import {Title} from "../../components/Typografy"
import ProgressFullScreen from "../../components/ProgressFullScreen";
import AccessoriesSlider from "../../components/sliders/AccessoriesSlider";
import {appStyle} from "../../theme/appStyle";
import {_endDate} from "../../components/BlueWeeks/Countdown";

function HomePage(props) {

    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();

    const pageData = useSelector(state => _get(state.pages.data, PAGES_SLUGS.HOMEPAGE, {}));
    const loading = useSelector(state => state.pages.request);
    const error = useSelector(state => state.pages.error);

    const bestPriceData = useSelector(state => state.bestPrice.data);
    const bestPriceLoading = useSelector(state => state.bestPrice.request);
    const bestPriceError = useSelector(state => state.bestPrice.error);

    const bestOffersData = useSelector(state => state.bestOffers.data);
    const bestOffersLoading = useSelector(state => state.bestOffers.request);
    const bestOffersError = useSelector(state => state.bestOffers.error);

    const promoEnded = (_endDate - new Date().getTime()) < 1000;

    useEffect(() => {
        window.scrollTo(0, 0);
        if (_isEmpty(pageData)) {
            dispatch(actions.fetchPage(PAGES_SLUGS.HOMEPAGE, 'home'));
        }
    }, [])

    useEffect(() => {
        if (_isEmpty(bestOffersData)) {
            dispatch(fetchBestOfferByModel())
        }

    }, [])

    useEffect(() => {
        if (_isEmpty(bestPriceData)) {
            dispatch(fetchBestPriceByModel())
        }

    }, [])

    return (
        <>
            {!loading && <>
                {!_isEmpty(_get(pageData, 'data.meta_data', {})) && <Seo data={pageData.data.meta_data}/>}
                {!_isEmpty(_get(pageData, 'data.hero_section.hero_section', {})) && <Fade>
                    <BannerSlider data={_get(pageData, 'data.hero_section.hero_section', [])}/>
                </Fade>}

                <SearchBox/>
                {/*<Grid>*/}
                {/*    <button onClick={()=>{props.methodDoesNotExist()}}>Break the world</button>*/}
                {/*</Grid>*/}
                {!_isEmpty(_get(pageData, 'data.body_types_section.body_types_section', {})) && <SectionBox>
                    <Fade>
                        <Container maxWidth={"sm"}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Title>{_get(pageData, 'data.body_types_section.title', '')}</Title>
                                </Grid>
                            </Grid>
                        </Container>
                        <div className={classes.containerOutter}>
                            <Grid container spacing={3}>
                                {_map(_get(pageData, 'data.body_types_section.body_types_section', []), (item, index) => {
                                    return <OfferCard
                                        key={index}
                                        to={_get(item, "attributes.url", '')}
                                        title={_get(item, "attributes.title", '')}
                                        content={_get(item, "attributes.content", '')}
                                        image={_get(item, "attributes.slide_image", '')}
                                    />
                                })}
                            </Grid>
                        </div>

                    </Fade>
                </SectionBox>}

                {!_isEmpty(_get(pageData, 'data.top_offers_section', {})) && <SectionBox>
                    <Fade>
                        <Container maxWidth={"sm"}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Title>{_get(pageData, 'data.top_offers_section.title', '')}</Title>
                                </Grid>
                            </Grid>
                        </Container>
                    </Fade>
                    {!_isEmpty(bestOffersData) && <Fade>
                        <Container>
                            <BestOfferSlider data={bestOffersData} cardUsed="cars"/>
                            <Grid container justify={"center"}>
                                <Button
                                    variant="outlined"
                                    size="large"
                                    color="primary"
                                    startIcon={<Icon className="brand-icon-offers"/>}
                                    component={RouterLink}
                                    to={`/${PAGES_SLUGS.PRODUCTS}`}>
                                    VEZI OFERTE PENTRU TOATE MODELELE
                                </Button>
                            </Grid>
                            <SpaceBox/>
                        </Container>
                    </Fade>}
                </SectionBox>}

                {/*Temp disable for blue Weeks*/}
                {(!_isEmpty(_get(pageData, 'data.last_mile_offers_section', {})) && promoEnded) && <SectionBox contrast={true}>
                    <Fade>
                        <Container maxWidth={"sm"}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Title>{_get(pageData, 'data.last_mile_offers_section.title', '')}</Title>
                                </Grid>
                            </Grid>
                        </Container>
                    </Fade>
                    {!_isEmpty(bestPriceData) && <Fade>
                        <Container>
                            <LastMileOfferSlider data={bestPriceData}/>
                        </Container>
                    </Fade>}
                </SectionBox>}

                {/*Blue weeks slider*/}
                {(!_isEmpty(bestPriceData) && !promoEnded) &&
                    <Box bgcolor="primary.dark">
                        <SectionBox>
                            <Fade>
                                <Container maxWidth={"sm"}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Box py={6}>
                                                <Typography
                                                    variant="h2"
                                                    component="h2"
                                                    align={"center"}
                                                    className={`${classes.whiteText} ${classes.weight500}`}
                                                >
                                                    Oferte Volvo Blue Weeks
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Container>
                            </Fade>
                            <Fade>
                                <Container>
                                    <BlueWeeksSlider data={bestPriceData}/>
                                </Container>
                            </Fade>
                        </SectionBox>
                    </Box>
                }


                {!_isEmpty(_get(pageData, 'data.explore_accessories', {})) && <SectionBox>
                    <Fade>
                        <Container maxWidth={"sm"}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Title>{_get(pageData, 'data.explore_accessories.title', '')}</Title>
                                </Grid>
                            </Grid>
                        </Container>
                    </Fade>
                    {!_isEmpty(_get(pageData, 'data.explore_accessories.explore_accessories', {})) && <Fade>
                        <Container>
                            <AccessoriesSlider data={_get(pageData, 'data.explore_accessories.explore_accessories', {})}/>
                            <Grid container justify={"center"}>
                                <Button
                                    variant="outlined"
                                    size="large"
                                    color="primary"
                                    startIcon={<Icon className="brand-icon-offers"/>}
                                    component={RouterLink}
                                    to={`/${PAGES_SLUGS.ACCESSORIES_INTRO}`}>
                                    VEZI TOATE ACCESORIILE
                                </Button>
                            </Grid>
                            <SpaceBox/>
                        </Container>
                    </Fade>}
                </SectionBox>}

                {!_isEmpty(_get(pageData, 'data.test_driver_section', {})) && <SectionBox gutterBottom={false}>
                    <TestDriveSection
                        data={_get(pageData, 'data.test_driver_section', {})}
                    />
                </SectionBox>}
            </>}
            {loading &&
            <ProgressFullScreen loading={true}/>
            }
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    ...appStyle(theme),
    containerOutter: {
        overflow: "Hidden",
        [theme.breakpoints.down('sm')]: {
            paddingLeft: `${theme.spacing(3) / 2}px`,
            paddingRight: `${theme.spacing(3) / 2}px`,
        },
    }
}));

export default HomePage;
